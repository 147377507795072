// Constants
import { DEFAULT_LANGUAGE, MENUS_TYPES } from '@/constants'
// Components
import CategoryFormByTabs from '@/components/elements/categories/CategoryFormByTabs'
import DraggableList from '@/components/ui/DraggableList'
import VuetifyContentLoading from '@/components/ui/VuetifyContentLoading'
// Mixins
import uiMixin from '@/mixins/uiMixin'
// Services
import { getCategoryById, getCategoriesByParentId } from '@/services/category'
import { updateCategoryById } from '@/services/category'
// Utils
import { get, isNil } from 'lodash'

export default {
  name: 'ManageMenusCategories',
  model: {
    prop: 'value',
    event: 'change'
  },
  components: {
    DraggableList,
    VuetifyContentLoading
  },
  mixins: [uiMixin],
  props: {
    // Modelo relacionado a la carta/menú ('places' o 'companies')
    model: {
      required: true,
      default: 'places',
      type: String
    },
    // UID del modelo en BD
    modelId: {
      required: true,
      type: String
    },
    // Opciones del modelo relacionado a la carta/menú
    modelOptions: {
      required: true,
      type: Object,
      default() {
        return {}
      }
    },
    // Categoría seleccionada por defecto
    value: {
      default: null,
      type: String
    },
    // Datos del menú/carta seleccionado
    menu: {
      default: null,
      type: String
    }
  },
  data() {
    return {
      categories: [], // Datos de las categorías
      menuData: null, // Datos del menú
      processingRequest: false,
      selectedCategory: this.value
    }
  },
  computed: {
    /**
     * Categorías a gestionar
     *
     * @return {array} - menus disponibles
     */
    currentCategories() {
      return this.categories.map((item) => {
        item.selected = item.id === this.selectedCategory
        return item
      })
    }
  },
  watch: {
    selectedCategory(value) {
      this.$emit('change', value)
    },
    menu: {
      async handler(newValue, oldValue) {
        // Obtenemos las categorías del menú
        await this.setMenuDataById(newValue)
        // Reseteamos valor de categoría
        if (!isNil(oldValue) && newValue !== oldValue) {
          this.selectedCategory = null
        }
      },
      immediate: true
    }
  },
  methods: {
    /**
     * handle change order in items
     *
     * @param {Array} items - items ordered
     */
    async handleChangeOrder(items) {
      if (Array.isArray(items)) {
        try {
          // Cambiando el orden
          await Promise.all(
            items.map(async (item, index) => {
              await updateCategoryById({
                id: item.id,
                order: index
              })
            })
          )
        } catch (error) {
          this.handleError(error.message)
        }
      }
    },
    /**
     * Evento lanzado cuando pulsamos sobre el botón de acción
     * (Abrimos una "dialog" con el formulario de categoría)
     *
     * @param {string} id - UID de la categoría
     */
    handleClickButton(id = null) {
      // Categoría seleccionada
      const selectedCategory = id
      // Menu seleccionado
      const selectedMenuType = get(this.menuData, 'type', MENUS_TYPES.place.value)
      // Opciones del modelo relacionado
      const areThereAdditionalLanguages = Boolean(
        get(this.modelOptions, 'additionalLanguages', []).length > 0
      )
      // Pestañas adicionales de configuración a mostrar en el formulario
      const tabs = [
        {
          id: 'basic',
          options: {
            areThereAdditionalLanguages,
            brandOptions: false, // para no mostrar las opciones de la marca
            defaultLanguage: get(this.modelOptions, 'defaultLanguage', DEFAULT_LANGUAGE),
            parentId: this.menu
          }
        }
      ]

      // Pestaña de traducciones
      if (selectedMenuType === MENUS_TYPES.place.value && areThereAdditionalLanguages) {
        tabs.push({
          id: 'translation',
          options: {
            additionalLanguages: get(this.modelOptions, 'additionalLanguages', []),
            defaultLanguage: get(this.modelOptions, 'defaultLanguage', DEFAULT_LANGUAGE)
          }
        })
      }

      // Mostramos dialog
      this.modifyAppDialog({
        title: isNil(selectedCategory) ? 'Crear nueva categoría' : 'Editar categoría',
        contentComponent: CategoryFormByTabs,
        contentComponentProps: {
          id: selectedCategory,
          model: this.model,
          modelId: this.modelId,
          tabs // obviamos la pestaña de horarios
        },
        hideActionButtons: true,
        visible: true
      })
    },

    /**
     * Evento lanzado cuando pulsamos sobre el uno de los item
     * de las lista
     *
     * @param {string} id - UID de la categoría
     */
    handleClickItem(id) {
      this.selectedCategory = id
    },

    /**
     * Show alert with error
     *
     * @param {string} error - error message
     */
    handleError(error) {
      this.modifyAppAlert({
        text: error,
        type: 'error',
        visible: true
      })
    },

    /**
     * Obtenemos los datos del menú seleccionado
     *
     * @param {string} id - UID del menu seleccionado
     */
    async setMenuDataById(id) {
      try {
        // loading
        this.processingRequest = true
        // Obtenemos los datos del menú
        this.menuData = !isNil(id) ? await getCategoryById(id) : null
        // Obtenemos las categoría del menú
        this.categories = !isNil(id) ? await getCategoriesByParentId(id) : []
      } catch (error) {
        this.handleError(error.message)
      } finally {
        this.processingRequest = false
      }
    }
  }
}
