export default {
  name: 'ManageMenusPriceInputs',
  props: {
    errorMessages: {
      type: Array,
      default() {
        return []
      }
    },
    items: {
      type: Array,
      default() {
        return []
      }
    }
  }
}
