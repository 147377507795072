// Components
import ListImagesPreview from '@/components/ui/ListImagesPreview'
import VuetifyContentLoading from '@/components/ui/VuetifyContentLoading'
// Mixins
import uiMixin from '@/mixins/uiMixin'
// Services
import { getCompanyActivationById } from '@/services/companyActivation'
// Utils
import { get, isNil } from 'lodash'
// Filters
import {
  activationStatusLabel,
  activationTypesLabel,
  formatDate,
  formatGoogleAddressObject
} from '@/filters'

export default {
  name: 'ActivationDetail',
  components: { ListImagesPreview, VuetifyContentLoading },
  filters: { activationStatusLabel, activationTypesLabel, formatDate },
  mixins: [uiMixin],
  props: {
    id: {
      required: true,
      type: String,
      default: null
    }
  },
  data() {
    return {
      // Datos de la activación
      companyActivationData: null,
      // Etiquetas atributos
      attrLabels: {
        brand: 'Marca',
        createTimestamp: 'Creación',
        email: 'Email',
        id: 'Identificador',
        images: 'Imágenes',
        name: 'Nombre',
        phone: 'Teléfono',
        place: 'Dirección',
        placeName: 'Nombre del establecimiento',
        placeUrl: 'Url del establecimiento',
        type: 'Tipo',
        status: 'Estado',
        updateTimestamp: 'Actualización'
      },
      // Loading
      processingRequest: true
    }
  },
  computed: {
    /**
     * Convertimos el objeto "companyActivationData" en un array
     * para así ordenar los campos alfabéticamente
     *
     * @returns {array}
     */
    companyActivationDataToArray() {
      return Object.entries(this.companyActivationData)
        .reduce((accAttributes, attr) => {
          accAttributes.push({
            id: attr[0],
            value: attr[1]
          })
          return accAttributes
        }, [])
        .filter((attr) => attr.value)
        .sort((a, b) => a.id.localeCompare(b.id, 'es'))
    }
  },
  async mounted() {
    await this.getEveryNeededData()
  },
  methods: {
    /**
     * Show alert with error
     *
     * @param {string} error - error message
     */
    handleError(error) {
      this.modifyAppAlert({
        text: error,
        type: 'error',
        visible: true
      })
    },
    /**
     * Inicializamos/obtenemos todos los datos necesarios
     * del componente
     */
    async getEveryNeededData() {
      try {
        if (isNil(this.id)) {
          throw new Error('No se ha indicado ningún identificador de activación.')
        }
        // Loading
        this.processingRequest = true
        // Obtenemos datos de la activación
        this.companyActivationData = await getCompanyActivationById(this.id)
      } catch (error) {
        // show error
        this.handleError(error.message)
        this.hideDialog()
      } finally {
        this.processingRequest = false
      }
    },
    /**
     * Hide dialog
     */
    hideDialog() {
      this.modifyAppDialog({
        visible: false
      })
    },
    /**
     * Dado el ID del atributo, devolvemos una etiqueta asociada
     * a dicho identificador
     *
     * @param {string} id - UID del atributo
     * @return {string} - etiqueta del atributo
     */
    getAttrLabel(id = null) {
      return get(this.attrLabels, id, 'Sin etiqueta')
    },
    /**
     * Indica el tipo de atributo que deseamos mostrar
     *
     * @param {string} id - UID del atributo
     * @return {string} - tipo del atributo
     */
    getAttrType(id = null) {
      let type = 'text'

      // Dependiendo del campo
      switch (id) {
        case 'placeUrl':
          type = 'url'
          break
        case 'images':
          type = 'images'
          break
        default:
          type = 'text'
      }

      return type
    },
    /**
     * Muestra el valor del atributo
     *
     * @param {string} id - UID del atributo
     * @return {string} - valor del atributo
     */
    getAttrValue(id = null) {
      let value = get(this.companyActivationData, id, null)

      // Dependiendo del campo
      switch (id) {
        case 'place':
          value = formatGoogleAddressObject(value)
          break
        case 'status':
          value = activationStatusLabel(value)
          break
        case 'type':
          value = activationTypesLabel(value)
          break
        case 'createTimestamp':
        case 'updateTimestamp':
          value = formatDate(value)
          break
        default:
        // TODO
      }

      return value
    }
  }
}
