// Constants
import { DEFAULT_LANGUAGE, MENUS_TYPES } from '@/constants'
// Components
import CategoryFormByTabs from '@/components/elements/categories/CategoryFormByTabs'
import DraggableList from '@/components/ui/DraggableList'
import VuetifyContentLoading from '@/components/ui/VuetifyContentLoading'
// Mixins
import uiMixin from '@/mixins/uiMixin'
// Services
import { getEveryMenusByCompanyId, getEveryMenusByPlaceId } from '@/services/category'
import { updateCategoryById } from '@/services/category'
// Utils
import { get, isNil } from 'lodash'

export default {
  name: 'ManageMenusMenus',
  model: {
    prop: 'value',
    event: 'change'
  },
  components: {
    DraggableList,
    VuetifyContentLoading
  },
  mixins: [uiMixin],
  props: {
    // Modelo relacionado a la carta/menú ('places' o 'companies')
    model: {
      required: true,
      default: 'places',
      type: String
    },
    // UID del modelo en BD
    modelId: {
      required: true,
      type: String
    },
    // Opciones del modelo relacionado a la carta/menú
    modelOptions: {
      required: true,
      type: Object,
      default() {
        return {}
      }
    },
    // Menu seleccionado por defecto
    value: {
      default: null,
      type: String
    }
  },
  data() {
    return {
      menus: [], // Datos de los menús
      processingRequest: true,
      selectedMenu: this.value
    }
  },
  watch: {
    selectedMenu(value) {
      this.$emit('change', value)
    }
  },
  async mounted() {
    await this.getEveryNeededData()
  },
  computed: {
    /**
     * Menus a gestionar
     *
     * @return {array} - menus disponibles
     */
    currentMenus() {
      return this.menus.map((item) => {
        item.selected = item.id === this.selectedMenu
        return item
      })
    }
  },
  methods: {
    /**
     * handle change order in items
     *
     * @param {Array} items - items ordered
     */
    async handleChangeOrder(items) {
      if (Array.isArray(items)) {
        try {
          // Cambiando el orden
          await Promise.all(
            items.map(async (item, index) => {
              await updateCategoryById({
                id: item.id,
                order: index
              })
            })
          )
        } catch (error) {
          this.handleError(error.message)
        }
      }
    },

    /**
     * Evento lanzado cuando pulsamos sobre el botón de acción
     * (Abrimos una "dialog" con el formulario de categoría)
     *
     * @param {string} id - UID del menú
     */
    handleClickButton(id = null) {
      // Menus seleccionado
      const selectedMenu = this.menus.find((menu) => menu.id === id)
      const selectedMenuType = get(selectedMenu, 'type', MENUS_TYPES.place.value)
      // Opciones del modelo relacionado
      const areThereAdditionalLanguages = Boolean(
        get(this.modelOptions, 'additionalLanguages', []).length > 0
      )
      // Pestañas adicionales de configuración a mostrar en el formulario
      const tabs = [
        {
          id: 'basic',
          options: {
            areThereAdditionalLanguages,
            brandOptions: false, // para no mostrar las opciones de la marca
            currency: get(this.modelOptions, 'currency', null),
            defaultLanguage: get(this.modelOptions, 'defaultLanguage', DEFAULT_LANGUAGE)
          }
        }
      ]

      // Pestaña de traducciones
      if (selectedMenuType === MENUS_TYPES.place.value && areThereAdditionalLanguages) {
        tabs.push({
          id: 'translation',
          options: {
            additionalLanguages: get(this.modelOptions, 'additionalLanguages', []),
            defaultLanguage: get(this.modelOptions, 'defaultLanguage', DEFAULT_LANGUAGE)
          }
        })
      }

      // Mostramos dialog
      this.modifyAppDialog({
        title: isNil(selectedMenu) ? 'Crear nueva carta/menú' : 'Editar carta/menú',
        contentComponent: CategoryFormByTabs,
        contentComponentProps: {
          id: get(selectedMenu, 'id', null),
          model: this.model,
          modelId: this.modelId,
          tabs // obviamos la pestaña de horarios
        },
        hideActionButtons: true,
        visible: true
      })
    },

    /**
     * Evento lanzado cuando pulsamos sobre el uno de los item
     * de las lista
     *
     * @param {string} id - UID de la categoría
     */
    handleClickItem(id) {
      this.selectedMenu = id
    },

    /**
     * Show alert with error
     *
     * @param {string} error - error message
     */
    handleError(error) {
      this.modifyAppAlert({
        text: error,
        type: 'error',
        visible: true
      })
    },

    /**
     * Obtenemos/establecemos todos los datos necesarios del componente
     */
    async getEveryNeededData() {
      try {
        this.processingRequest = true
        // Obtenemos los menús del establecimiento
        if (this.model === 'places') {
          this.menus = await getEveryMenusByPlaceId(this.modelId)
        }
        // Obtenemos los menús de la compañía
        if (this.model === 'companies') {
          this.menus = await getEveryMenusByCompanyId(this.modelId)
        }
      } catch (error) {
        this.handleError(error.message)
      } finally {
        this.processingRequest = false
      }
    }
  }
}
