// Constants
import { ADDONS } from '@/constants'
// Components
import ActivationDetail from '@/components/elements/activations/ActivationDetail'
import CardContainer from '@/components/ui/CardContainer'
import ManageMenusCategories from '@/components/elements/manageMenus/ManageMenusCategories'
import ManageMenusDishes from '@/components/elements/manageMenus/ManageMenusDishes'
import ManageMenusMenus from '@/components/elements/manageMenus/ManageMenusMenus'
import VuetifyPopoverMenu from '@/components/ui/VuetifyPopoverMenu'
import VuetifyToolBar from '@/components/ui/VuetifyToolBar'
import VuetifyContentLoading from '@/components/ui/VuetifyContentLoading'
// Mixins
import uiMixin from '@/mixins/uiMixin'
// Vuex
import { mapState } from 'vuex'
// Services
import { getPlaceById } from '@/services/place'
// Utils
import { get, isNil } from 'lodash'
import { getEnvironmentVariable } from '@/utils'

export default {
  name: 'PlacesManageMenus',
  components: {
    CardContainer,
    ManageMenusCategories,
    ManageMenusDishes,
    ManageMenusMenus,
    VuetifyPopoverMenu,
    VuetifyContentLoading,
    VuetifyToolBar
  },
  mixins: [uiMixin],
  data() {
    return {
      id: this.$route.params.id || null, // UID del establecimiento
      placeData: null, // Datos del establecimiento
      processingRequest: true,
      selectedCategory: null, // UID de la categoría seleccionada
      selectedMenu: null // UID del menú seleccionado
    }
  },
  computed: {
    ...mapState('app', ['extraSmallDevice']),
    /**
     * Identificador del ID de activación de la compañía
     *
     * @return {String}
     */
    companyActivationId() {
      return get(this.placeData, 'companyActivationId', null)
    },
    /**
     * Items del listado de acciones del "popoverMenu"
     */
    popoverMenuItems() {
      return !isNil(this.companyActivationId)
        ? [
            {
              icon: 'mdi-file-edit',
              itemFn: this.handleActivationDetailDialog,
              label: 'Datos de la activación'
            }
          ]
        : []
    },
    /**
     * Ocultar o mostrar botón "acción" en navegación
     *
     * @return {Boolean}
     */
    showActionButtonToolBar() {
      return !isNil(this.companyActivationId)
    },
    /**
     * La "toolBar" es sticky
     *
     * @return {Boolean}
     */
    toolBarIsSticky() {
      return this.extraSmallDevice
    }
  },
  async mounted() {
    // Capturamos evento
    this.$root.$on('reload-list', this.getEveryNeededData)
    // Llamamos al método inicial
    await this.getEveryNeededData()
  },
  destroyed() {
    // Paramos escucha
    this.$root.$off('reload-list')
  },
  methods: {
    /**
     * Evento lanzado cuando pulsamos sobre alguno de los
     * item del "PopoverMenu"
     *
     * @param {Number} index - actions index
     */
    async handleClickPopoverMenuItem(index) {
      if (
        this.popoverMenuItems[index] &&
        typeof this.popoverMenuItems[index].itemFn === 'function'
      ) {
        this.popoverMenuItems[index].itemFn()
      }
    },
    /**
     * Cuando pulsamos sobre el botón de "Datos de activación"
     */
    handleActivationDetailDialog() {
      this.modifyAppDialog({
        title: 'Datos de la activación',
        contentComponent: ActivationDetail,
        contentComponentProps: {
          id: this.companyActivationId
        },
        optionsActionsButtons: {
          acceptButtonHide: true,
          cancelButtonText: 'Cerrar'
        },
        visible: true
      })
    },
    /**
     * Obtenemos/establecemos todos los datos necesarios del componente
     */
    async getEveryNeededData() {
      this.processingRequest = true
      try {
        // Comprobamos si el ID del establecimiento existe
        this.placeData = await getPlaceById(this.id, {
          includeAddOnsConfigs: true,
          includeSubscriptions: false
        })

        if (isNil(this.placeData)) {
          throw new Error('No existen datos del establecimiento indicado')
        }

        if (get(this.placeData, 'brand', null) !== getEnvironmentVariable('VUE_APP_BRAND')) {
          throw new Error('El establecimiento indicado no pertenece a tu marca')
        }

        // Opciones del addOn "place" del establecimiento
        const addOnConfigs = get(this.placeData, 'addOnConfigs', [])
        const placeAddon = addOnConfigs.reduce((sumAddOns, addOn) => {
          if (addOn.id === ADDONS.place) {
            sumAddOns = { ...sumAddOns, ...(addOn.configFields || {}) }
          }
          return sumAddOns
        }, {})

        // Unificamos todos los datos en la misma variable
        this.placeData = {
          ...this.placeData,
          options: {
            ...placeAddon
          }
        }
      } catch (error) {
        // Mostramos errores
        this.modifyAppAlert({
          type: 'error',
          text: error.message,
          visible: true
        })
        // Devolvemos al listado de establecimientos
        this.routerPushTo({
          name: 'places-list'
        })
      } finally {
        this.processingRequest = false
      }
    }
  }
}
