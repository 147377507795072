// Constants
import { DEFAULT_LANGUAGE } from '@/constants'
// Components
import AllergensIcons from '@/components/ui/AllergensIcons'
import DishFormByTabs from '@/components/elements/dishes/DishFormByTabs'
import DraggableList from '@/components/ui/DraggableList'
import ManageMenusPriceInputs from '@/components/elements/manageMenus/ManageMenusPriceInputs'
import ManageMenusPriceOptions from '@/components/elements/manageMenus/ManageMenusPriceOptions'
import VuetifyContentLoading from '@/components/ui/VuetifyContentLoading'
import VuetifyDialog from '@/components/ui/VuetifyDialog'
// Mixins
import formMixin from '@/mixins/formMixin'
import uiMixin from '@/mixins/uiMixin'
// Vue-validate plugin
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
// Services
import {
  getEveryDishesByCategoryId,
  updateDishById,
  createDish,
  deleteDishById
} from '@/services/dish'
import {
  getDefaultRations,
  getEveryRationsByPlaceId,
  getEveryRationsByCompanyId
} from '@/services/ration'
// Utils
import { cloneDeep, debounce, get, isNil, set } from 'lodash'
import { stringToNumber } from '@/utils'

export default {
  name: 'ManageMenusDishes',
  components: {
    AllergensIcons,
    DraggableList,
    ManageMenusPriceInputs,
    ManageMenusPriceOptions,
    VuetifyContentLoading,
    VuetifyDialog
  },
  mixins: [formMixin, uiMixin, validationMixin],
  props: {
    // Modelo relacionado a la carta/menú ('places' o 'companies')
    model: {
      required: true,
      default: 'places',
      type: String
    },
    // UID del modelo en BD
    modelId: {
      required: true,
      type: String
    },
    // Opciones del modelo relacionado a la carta/menú
    modelOptions: {
      required: true,
      type: Object,
      default() {
        return {}
      }
    },
    // Categoría seleccionada
    category: {
      default: null,
      type: String
    }
  },
  data() {
    return {
      // Form
      formFields: {
        dishes: []
      },
      formFieldsValidations: {
        dishes: {
          id: {
            required: 'Campo obligatorio'
          },
          name: {
            required: 'Campo obligatorio'
          },
          prices: {
            notEmpty: 'Debes completar los campos'
          }
        }
      },
      // Others
      debouncedOnSubmit: debounce(() => {
        this.onSubmit()
      }, 1000), // Encapsulamos el método "onSubmit"
      dishesData: [], // Datos de los platos de la categoriía
      modelRations: {}, // Raciones del modelo con el que trabajamos
      numberDishesToCreate: 10,
      showPriceOptionsDialog: false,
      processingRequest: false,
      selectedItems: []
    }
  },
  computed: {
    /**
     * Componente de "opciones de precios" para usar
     * en el "diálogo"
     *
     * @return {object}
     */
    manageMenusPriceOptionsComponent() {
      return ManageMenusPriceOptions
    }
  },
  watch: {
    category: {
      async handler(value) {
        // Obtenemos los platos de la categoría
        await this.setDishesByCategoryId(value)
      },
      immediate: true
    },
    /**
     * Cuando hay modificaciones en los campos, salvamos los datos
     */
    'formFields.dishes': {
      deep: true,
      handler(newValue, oldValue) {
        // Condiciones para impedir que se llame a la
        // actualización en la carga inicial y cuando
        // un item es eliminado
        if (oldValue.length > 0 && oldValue.length <= newValue.length) {
          this.debouncedOnSubmit()
        }
      }
    }
  },
  methods: {
    /**
     * handle change order in items
     *
     * @param {Array} items - items ordered
     */
    async handleChangeOrder(items) {
      // Recorremos el orden de los items que nos llegan
      // y modificamos el campo "order" de estos
      items.forEach((item, index) => {
        const dishIndex = this.formFields.dishes.findIndex((dish) => {
          return dish.id === item.id
        })

        if (dishIndex > -1) {
          set(this.formFields.dishes[dishIndex], 'order', index)
        }
      })
    },
    /**
     * Evento lanzado cuando pulsamos sobre el botón de
     * crear nuevo plato
     */
    async handleCreateDish() {
      // Número de platos a crear
      const numberDishesToCreate =
        Number.parseInt(this.numberDishesToCreate, 10) > 0
          ? Number.parseInt(this.numberDishesToCreate, 10)
          : 1
      // Función que se llama recursivamente para crear los productos
      const fnCreateDish = async (total = 1, index = 1) => {
        // Creamos plato en BD
        const { dish } = await createDish(
          {
            allergens: [],
            name: `Producto nuevo ${index}`,
            categories: [this.category],
            prices: {
              [this.category]: null
            },
            order: this.formFields.dishes.length
          },
          null,
          {
            additionalLanguages: this.modelOptions.additionalLanguages,
            defaultLanguage: this.modelOptions.defaultLanguage
          }
        )

        // Incluimos nuevo plato en el array de platos
        this.formFields.dishes.push({
          ...dish,
          // Convertimos el objeto "prices" a un "array"
          prices: this.parsePricesObjectToArray(
            get(dish, `prices.${this.category}`, null),
            this.modelRations
          )
        })

        // Vuelvo a llamar
        return total > index ? await fnCreateDish(total, index + 1) : true
      }

      try {
        // Loading
        this.processingRequest = true
        // generamos los platos y los incluimos en el formulario
        await fnCreateDish(numberDishesToCreate, 1)
      } catch (error) {
        this.handleError(error.message)
      } finally {
        // Loading
        this.processingRequest = false
      }
    },
    /**
     * Evento lanzado cuando pulsamos sobre el botón
     * de borrado de alguno de los elementos
     *
     * @param {string} id - UID del item a eliminar
     */
    async handleClickDeleteButton(id) {
      this.modifyAppAlert({
        actionButtonFn: async () => {
          // Eliminamos elemento de la lista
          const currentFormFields = this.formFields.dishes.filter((dish) => {
            return dish.id !== id
          })
          // Eliminamos el elemento de la lista
          this.formFields.dishes = currentFormFields
          // Eliminamos plato de la BD
          await deleteDishById(id, this.category)
          // Siempre que elimina algún producto,
          // se marcan los productos que le siguen
          // no tengo ni idea de el motivo de esto,
          // para evitar dicho error, realizo esta llamada
          // this.unSelectedItems()
        },
        actionButtonText: 'Borrar',
        text: '¿Desea borrar el producto?',
        type: 'warning',
        visible: true
      })
    },
    /**
     * Evento lanzado cuando pulsamos sobre el botón
     * de edición de alguno de los elementos
     *
     * @param {string} id - UID del item a editar
     */
    async handleClickEditButton(id) {
      // Opciones del modelo relacionado
      const areThereAdditionalLanguages = Boolean(
        get(this.modelOptions, 'additionalLanguages', []).length > 0
      )
      // Pestañas adicionales de configuración a mostrar en el formulario
      const tabs = [
        {
          id: 'basic',
          options: {
            additionalLanguages: get(this.modelOptions, 'additionalLanguages', []),
            areThereAdditionalLanguages,
            brandOptions: false, // para no mostrar las opciones de la marca
            currency: get(this.modelOptions, 'currency', {}),
            defaultLanguage: get(this.modelOptions, 'defaultLanguage', DEFAULT_LANGUAGE)
          }
        },
        {
          id: 'allergens',
          options: {}
        }
      ]

      // Pestaña de traducciones
      if (areThereAdditionalLanguages) {
        tabs.push({
          id: 'translation',
          options: {
            additionalLanguages: get(this.modelOptions, 'additionalLanguages', []),
            defaultLanguage: get(this.modelOptions, 'defaultLanguage', DEFAULT_LANGUAGE)
          }
        })
      }

      // Mostramos dialog
      this.modifyAppDialog({
        title: 'Editar producto',
        contentComponent: DishFormByTabs,
        contentComponentProps: {
          id,
          categoryId: this.category,
          model: this.model,
          modelId: this.modelId,
          tabs
        },
        hideActionButtons: true,
        visible: true
      })
    },
    /**
     * Cuando pulsamos en el botón de "Tipos de precios"
     */
    handleClickPricesButton() {
      this.handleTogglePriceOptionsDialog()
    },
    /**
     * Cuando pulsamos en el botón de "Seleccionar/Deseleccionar"
     * elementos de la lista
     */
    handleClickSelectButton() {
      if (this.selectedItems.length > 0) {
        this.selectedItems = []
      } else {
        this.selectedItems = this.formFields.dishes.map((dish) => {
          return dish.id
        })
      }
    },
    /**
     * Manejador del evento del diálogo de las
     * "opciones de precios"
     *
     * @param {object | null | string} prices - price object
     */
    async handlePriceOptionsSelected(prices) {
      // Ocultamos diálogo
      this.handleTogglePriceOptionsDialog()

      // TODO - Piltrafa para cerrar el cuadro de diálogo de
      // las opciones de precio sin hacer nada más
      if (typeof prices === 'string' && prices === 'cancel') {
        return
      }

      // Repasamos cada uno de los elementos seleccionados
      // para aplicar dichos precios
      this.selectedItems.forEach((item) => {
        const itemIndex = this.formFields.dishes.findIndex((dish) => {
          return dish.id === item
        })

        if (itemIndex > -1) {
          this.$set(this.formFields.dishes[itemIndex], 'prices', cloneDeep(prices))
        }
      })

      // Deseleccionamos los checkboxs
      this.selectedItems = []
      // Actualizamos las raciones del modelo
      this.modelRations = await this.getModelRations(this.model, this.modelId)
    },
    /**
     * Show alert with error
     *
     * @param {string} error - error message
     */
    handleError(error) {
      this.modifyAppAlert({
        text: error,
        type: 'error',
        visible: true
      })
    },
    /**
     * Mostramos/ocultamos el diálogo de opciones de precios
     */
    handleTogglePriceOptionsDialog() {
      this.showPriceOptionsDialog = !this.showPriceOptionsDialog
    },
    /**
     * Obtenemos las raciones del modelo donde nos
     * encontramos
     *
     * @param {string} model - Modelo asociado al menú
     * @param {string} modelId - UID del Modelo asociado al menú
     * @return {object} - Objeto con las raciones asociadas
     */
    async getModelRations(model, modelId) {
      const defaultRations = getDefaultRations()
      const modelRations =
        model === 'places'
          ? await getEveryRationsByPlaceId(modelId)
          : await getEveryRationsByCompanyId(modelId)
      const modelRationsInObject = modelRations.reduce((accRations, ration) => {
        accRations[ration.id] = {
          name: ration.name,
          order: ration.order,
          price: ration.price
        }
        return accRations
      }, {})

      return {
        ...defaultRations,
        ...modelRationsInObject
      }
    },
    /**
     * Obtenemos los platos de la categoría seleccionada
     *
     * @param {string} category - UID de la categoría
     */
    async setDishesByCategoryId(category) {
      try {
        // Loading
        this.processingRequest = true
        // Actualizamos las raciones del modelo
        this.modelRations = await this.getModelRations(this.model, this.modelId)
        // Obtenemos los platos de la categoría
        this.dishesData = !isNil(category) ? await getEveryDishesByCategoryId(category) : []
        // Convertimos el objeto "prices" de cada plato en un array
        // para que funcione correctamente con Vuelidate y draggableList
        this.formFields.dishes = this.dishesData.map((dish) => {
          // Precios de la categoría donde nos encontramos
          const prices = get(dish, `prices.${category}`, null)
          const parsedPricesToArray = this.parsePricesObjectToArray(prices, this.modelRations)
          return {
            ...dish,
            prices: parsedPricesToArray
          }
        })
      } catch (error) {
        this.handleError(error.message)
      } finally {
        this.processingRequest = false
      }
    },
    /**
     * Convertimos un objeto "prices" en array para trabajar
     * con su validación y uso con otros componentes
     *
     * @param {Object} prices - campos "precios" del item (producto)
     * @param {Object} rations - raciones del establecimiento
     * @return {Array}
     */
    parsePricesObjectToArray(prices = {}, rations = {}) {
      if (isNil(prices)) {
        return prices
      }

      if (typeof prices === 'object' && Object.keys(prices).length) {
        const pricesArray = Object.entries(prices).reduce((sumRations, ration, index) => {
          sumRations.push({
            id: ration[0],
            name: get(rations, `${ration[0]}.name`, ''),
            order: !isNil(ration[1]) && !isNil(ration[1].order) ? ration[1].order : index,
            price: stringToNumber(ration[1].price)
          })
          return sumRations
        }, [])

        return pricesArray.sort((a, b) => {
          return a.order - b.order
        })
      }

      return []
    },
    /**
     * Convertimos el array de precios (prices) en
     * un objeto para almacenar este en la base de datos
     * correctamente
     *
     * @param {Array} prices - campos "precios" del item (producto)
     * @param {Object} dish - datos del plato (precios) almacenado en BD
     * @return {Object}
     */
    parsePricesArrayToObject(prices, dish = null) {
      const dishPrices = !isNil(dish) ? get(dish, 'prices', {}) : {}
      const parsePrices =
        !isNil(prices) && Array.isArray(prices)
          ? prices.reduce((sumRations, ration, index) => {
              sumRations[ration.id] = {
                order: index,
                price: stringToNumber(ration.price)
              }
              return sumRations
            }, {})
          : null

      return { ...dishPrices, [this.category]: parsePrices }
    },

    /**
     * Is triggered after the form is correctly
     * validated by "Vue-validate"
     */
    async afterSubmit() {
      try {
        // Actualizamos los platos uno por uno
        await Promise.all(
          this.formFields.dishes.map(async (dish) => {
            // Obtenemos, si existe, los precios del plato ya almacenado en BD
            const currentDish = this.dishesData.find((dishData) => {
              return dishData.id === dish.id
            })
            // Preparamos el campo "prices" del plato para que este
            // sea almacenado correctamente
            const parseDish = {
              ...dish,
              prices: this.parsePricesArrayToObject(dish.prices, currentDish)
            }
            // Guardamos platos
            const dishUpdated = await updateDishById(parseDish, {
              additionalLanguages: this.modelOptions.additionalLanguages,
              defaultLanguage: this.modelOptions.defaultLanguage
            })

            return dishUpdated
          })
        )
        // Incluimos los datos de los platos en
      } catch (error) {
        this.handleError(error.message)
      } finally {
        this.formProcessing = false
      }
    }
  },
  // Validations with Vue-validate
  validations: {
    formFields: {
      dishes: {
        $each: {
          allergens: {},
          id: {
            required
          },
          name: {
            required
          },
          prices: {
            notEmpty: (value) => {
              return (
                isNil(value) ||
                (Array.isArray(value) && value.every((v) => !isNil(v.price) && v.price > 0))
              )
            }
          }
        }
      }
    }
  }
}
