// Services
import {
  createRationInCompany,
  createRationInPlace,
  getDefaultRations,
  getMetaRationByCompanyId,
  getMetaRationByPlaceId,
  getEveryRationsByCompanyId,
  getEveryRationsByPlaceId,
  updateMetaRationById
} from '@/services/ration'
// Components
import FormButtons from '@/components/ui/FormButtons'

const uniqid = require('uniqid')

export default {
  name: 'ManageMenusPriceOptions',
  components: { FormButtons },
  props: {
    // Tipo del modelo (compañía o establecimiento)
    model: {
      type: String,
      required: true,
      default: 'places'
    },
    // UID del modelo (compañía o establecimiento) que tratamos
    modelId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      // Opciones de precios
      priceOptions: null,
      // Tipos de precios
      priceTypes: [
        {
          id: 0,
          label: 'Sin precio'
        },
        {
          id: 1,
          label: 'Precio único'
        },
        {
          id: 2,
          label: 'Por ración'
        }
      ],
      // Opciones de precios seleccionados
      selectedPriceOptions: [],
      // Tipo de precio seleccionado
      selectedPriceType: 0
    }
  },
  computed: {
    /**
     * Las raciones (tipos de precios) pueden estar vinculadas
     * al establecimiento o la compañía
     *
     * @return {Boolean}
     */
    modelIsPlace() {
      return this.model === 'places'
    }
  },
  methods: {
    /**
     * Evento lanzado cuando pulsamos los botones de
     * cambio de tipo de precio
     *
     * @param {number} type - price type
     */
    async handleCurrentPriceTypes(type) {
      switch (type) {
        case 0: // Sin precio
          this.priceOptions = null
          break
        case 1: // Precio único
          this.priceOptions = await this.getEveryRations('default')
          break
        case 2: //Por tipo de tapa
          this.priceOptions = await this.getEveryRations('custom')
          break
        default:
          this.priceOptions = null
      }
    },
    /**
     * Evento lanzado cuando pulsamos sobre el botón de "Añadir"
     */
    async handleAddRation() {
      const metaRation = this.modelIsPlace
        ? await getMetaRationByPlaceId(this.modelId)
        : await getMetaRationByCompanyId(this.modelId)

      if (metaRation) {
        this.priceOptions.push({
          id: uniqid(),
          name: 'Nombre ración',
          metas: { [metaRation.id]: true },
          order: 0,
          price: null,
          new: true
        })
      }
    },
    /**
     * Evento lanzado cuando pulsamos sobre el botón de "Aceptar"
     */
    async handleAcceptButton() {
      if (this.selectedPriceType === 2) {
        // Meta-racion genérica
        const { id, metaFields } = this.modelIsPlace
          ? await getMetaRationByPlaceId(this.modelId)
          : await getMetaRationByCompanyId(this.modelId)
        // Salvamos los nuevos precios en BD
        const newRations = await Promise.all(
          this.priceOptions
            .filter((price) => {
              return price.new
            })
            .map(async (price, index) => {
              delete price.new
              const { id, ...data } = price
              const ration = this.modelIsPlace
                ? await createRationInPlace(
                    { ...data, order: this.priceOptions.length + index },
                    this.modelId,
                    id
                  )
                : await createRationInCompany(
                    { ...data, order: this.priceOptions.length + index },
                    this.modelId,
                    id
                  )

              return ration
            })
        )

        // Id de las raciones
        newRations.reduce((sumRationsIds, ration) => {
          sumRationsIds[ration.ration.id] = true
          return sumRationsIds
        }, metaFields)

        // Actualizamos meta-ración padre
        await updateMetaRationById({ id, metaFields })

        // Only pass the selected options
        if (this.selectedPriceOptions.length) {
          this.priceOptions = this.priceOptions.reduce((sumPrices, price) => {
            if (this.selectedPriceOptions.indexOf(price.id) > -1) {
              sumPrices.push({
                id: price.id,
                name: price.name,
                price: price.price,
                order: price.order
              })
            }
            return sumPrices
          }, [])
        } else {
          this.priceOptions = null
        }
      }

      // Evento incluido en el componente "VuetifyDialog" para poder emitir eventos
      // desde el componente cargado en su interior
      this.$emit('onEventComponent', this.priceOptions)

      // Reset everything
      this.selectedPriceOptions = []
      this.selectedPriceType = 0
      this.priceOptions = null
    },
    /**
     * Evento lanzado cuando pulsamos sobre el botón de "Cancelar"
     */
    async handleCancelButton() {
      this.$emit('onEventComponent', 'cancel')
    },
    /**
     * Obtenemos los tipos de raciones
     *
     * @param {string} rationsType - "default", "custom"
     * @return {array} - rations array
     */
    async getEveryRations(rationsType = 'default') {
      const customRations = this.modelIsPlace
        ? await getEveryRationsByPlaceId(this.modelId)
        : await getEveryRationsByCompanyId(this.modelId)
      // Lo convertimos en un array para tratarlo en el padre
      const defaultRations = Object.entries(getDefaultRations()).reduce((accRations, ration) => {
        accRations.push({
          id: ration[0],
          ...ration[1]
        })
        return accRations
      }, [])

      if (rationsType === 'default') {
        return defaultRations
      }
      if (rationsType === 'custom') {
        return customRations
      }

      return []
    }
  }
}
