// Vuex
import { mapGetters } from 'vuex'
// Utils
import { getPathImage } from '@/utils'

export default {
  name: 'AllergensIcons',
  props: {
    allergens: {
      required: true,
      type: Array,
      default() {
        return []
      }
    },
    justify: {
      type: String,
      default: 'left'
    },
    size: {
      type: String,
      default: 'lg' // 'lg' or 'sm'
    }
  },
  computed: {
    ...mapGetters('config', ['allergensData'])
  },
  methods: {
    /**
     * Get icon to draw allergen
     *
     * @param {string} id
     * @return {string} - path to icon
     */
    getPathAllergenIcon(id) {
      const currentIndex = this.getIndexAllergensById(id)

      if (currentIndex > -1) {
        const currentAllergen = { ...this.allergensData[currentIndex] }
        // Remove "img" from it is not neccesary and '"'
        let pathIcon = currentAllergen.icon.replace(/\/img\//i, '')
        pathIcon = pathIcon.replace(/"/gim, '')
        return getPathImage(pathIcon)
      }

      return false
    },
    /**
     * Get the allergen name
     *
     * @param {string} id - id allergens array
     * @return {string} - allergen name
     */
    getAllergenName(id) {
      const currentIndex = this.getIndexAllergensById(id)

      if (currentIndex > -1) {
        return this.allergensData[currentIndex].name
      }

      return false
    },
    /**
     * Get index from allergens array by Id allergen
     *
     * @param {string} id - id allergens array
     */
    getIndexAllergensById(id) {
      const allergensData = Object.entries(this.allergensData)

      return allergensData && Array.isArray(allergensData)
        ? allergensData.findIndex((allergen) => {
            return allergen[1].id === Number.parseInt(id, 10)
          })
        : -1
    }
  }
}
